import styled from 'styled-components';

export const InTestsWeTrust = styled.p`
  margin: 5px 0 0 0;
  user-select: none;
  font-size: 1rem;
  padding-left: 8rem;
  color: darkgray;
  font-family: 'JetBrains Mono', serif;
`;
