import React, { useContext } from 'react';
import styled from 'styled-components';
import { VscFilter } from 'react-icons/vsc';
import { Card } from '@/components/card';
import { VSpace } from '@/components/spacing';
import { InTestsWeTrust } from '@/features/customer-success/common';
import { GeneralFilters } from '@/features/customer-success/generalFilters';
import { CSContext } from '@/features/customer-success/context';
import { CheckboxPopover } from '@/components/checkbox-popover';
import { FilterChip } from '@/components/filter-chip';
import { Pages } from '@/webapi/pages';

export function GeneralOverView() {
  const { generalStats: state } = useContext(CSContext);

  return (
    <Container>
      <HeaderContainer>GENERAL OVERVIEW✨</HeaderContainer>
      <GeneralFilters />
      <VSpace value={4} />
      <Blocks>
        <Card nohover>
          <BlockTitle>
            <span>Number Of Running Experiences</span>
            <Numeral>{state?.running?.toLocaleString() || 0}</Numeral>
          </BlockTitle>
        </Card>
        <Card nohover>
          <BlockTitle>
            <span>Number Of Tests</span>
            <Numeral>{state?.abTests?.toLocaleString() || 0}</Numeral>
          </BlockTitle>
        </Card>
        <Card nohover>
          <BlockTitle>
            <span>A/B Tests Moved To 100%</span>
            <Numeral>{state?.movedTo100?.toLocaleString() || 0}</Numeral>
          </BlockTitle>
        </Card>
        <Card nohover>
          <BlockTitle>
            <span>Paused Tests</span>
            <Numeral>{state?.paused?.toLocaleString() || 0}</Numeral>
          </BlockTitle>
        </Card>
        <Card nohover>
          <BlockTitle>
            <span>Clients Without Running Tests</span>
            <Numeral>
              {state?.clientsWithoutTests?.toLocaleString() || 0}
            </Numeral>
            <CheckboxPopover
              text="open account"
              key={`x${Math.random()}`}
              options={
                state?.listOfClientsWithoutTests?.map((x) => ({
                  selected: false,
                  caption: x,
                  id: x,
                })) || []
              }
              emptyState=""
              onChange={(x) => {
                x?.filter((x) => x.selected)?.forEach((x) => {
                  window.open(`${Pages.DASHBOARD}?al=${x.id}`, `tab${x.id}`);
                });
              }}
            >
              <FilterChip mw="10rem" className="label-select">
                <span className="long-text">open account</span>
                <VscFilter size={14} />
              </FilterChip>
            </CheckboxPopover>
          </BlockTitle>
        </Card>
        <Card nohover>
          <BlockTitle>
            <span>Drafts</span>
            <Numeral>{state?.drafts?.toLocaleString() || 0}</Numeral>
          </BlockTitle>
        </Card>
      </Blocks>
      <VSpace value={4} />
      <InTestsWeTrust>STAY CALM AND TEST ON 👑</InTestsWeTrust>
      <VSpace />
    </Container>
  );
}

const BlockTitle = styled.p`
  display: flex;
  flex-direction: column;
  font-family: 'JetBrains Mono', serif;
  color: #798794;
  font-weight: 800;
  font-size: 2rem;
`;
const Container = styled.div`
  max-width: 80vw;
`;

const Blocks = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6rem;
`;
const HeaderContainer = styled.h2`
  font-family: 'JetBrains Mono', serif;
  font-size: 4rem;
  text-align: center;
`;
const Numeral = styled.span`
  margin-top: 1rem;
  font-size: 3rem;
  color: black;
`;
