import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import { VscFilter } from 'react-icons/vsc';
import { useQueryParam } from 'use-query-params';
import { FilterChip } from '@/components/filter-chip';
import { RangePopover } from '@/features/dashboard/shared/date-picker/range_popover';
import { CheckboxPopover } from '@/components/checkbox-popover';
import { CSContext } from '@/features/customer-success/context';
import { SearchInput } from '@/components/searchInput';
import { BigButton } from '@/components/big-button';
import { HSpace } from '@/components/spacing';
import { Spinner } from '@/Spinner';
import { DateRange } from '@/features/dashboard/reports_v2/models';

export function TableFilters() {
  const ctx = useContext(CSContext);
  const [rn, setDateRange] = useQueryParam(`rn`);
  useEffect(() => {
    if (!rn) {
      setDateRange(DateRange.LATEST);
    }
  }, []);
  return (
    <Container>
      <Container style={{ padding: `4px 0 0 0` }}>
        <FilterChip
          onClick={() => ctx.toggleChipFilter(false)}
          vselected={!ctx.state.isTrialFilter}
        >
          All {ctx.state.total ? `(${ctx.state.total})` : ``}
        </FilterChip>
        <FilterChip
          onClick={() => ctx.toggleChipFilter(true)}
          vselected={ctx.state.isTrialFilter}
        >
          Trial {ctx.state.totalTrial ? `(${ctx.state.totalTrial})` : ``}
        </FilterChip>
        <SizeFilter />
        <ClientFilter />
      </Container>
      <SearchInput
        className="only-desktop"
        defaultValue={ctx.state.searchTerm}
        grow
        onChange={(e) => ctx.changeSearch(e.target.value)}
      />
      <HSpace />
      <RangePopover withLatestVersionOption />
      <HSpace />
      <HSpace />
      <BigButton onClick={() => ctx.selectPage(0)}>
        {!ctx.loading ? (
          `Submit`
        ) : (
          <Spinner size={20} color="pink" mt="0" relative />
        )}
      </BigButton>
      <HSpace />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;
function ClientFilter() {
  const ctx = useContext(CSContext);
  return (
    <CheckboxPopover
      key={`clientFilter${Math.random()}`}
      options={ctx.state.aliasesFilter}
      emptyState=""
      onChange={ctx.setAliasFilter}
    >
      <FilterChip
        className={`label-select ${
          ctx.selectedAlias?.length > 0 ? `selected` : ``
        }`}
      >
        <span className="long-text">Clients</span>
        <VscFilter size={14} />
      </FilterChip>
    </CheckboxPopover>
  );
}

function SizeFilter() {
  const ctx = useContext(CSContext);
  return (
    <CheckboxPopover
      key={`sizeFilter${Math.random()}`}
      options={ctx.state.sizeFilter}
      emptyState=""
      onChange={ctx.setSizeFilter}
    >
      <FilterChip
        className={`label-select ${
          ctx.selectedSizeFilter?.length > 0 ? `selected` : ``
        }`}
      >
        <span className="long-text">Size</span>
        <VscFilter size={14} />
      </FilterChip>
    </CheckboxPopover>
  );
}
