import React from 'react';
import styled from 'styled-components';
import { TableHeader } from '@/features/customer-success/tableHeader';
import { VSpace } from '@/components/spacing';
import { TableFilters } from '@/features/customer-success/tableFilters';
import { TableRoot } from '@/features/customer-success/table';
import { GeneralOverView } from '@/features/customer-success/general';

export function Root() {
  return (
    <Container>
      <VSpace value={4} />
      <TableHeader />
      <TableFilters />
      <VSpace />
      <TableRoot />
      <VSpace />
      <GeneralOverView />
    </Container>
  );
}

const Container = styled.div`
  overflow: scroll;
  height: 100%;
  background-color: whitesmoke;
  position: relative;
  display: flex;
  flex-direction: column;

  align-items: center;
`;
