import React, { useContext } from 'react';
import styled from 'styled-components';
import { VscFilter } from 'react-icons/vsc';
import { FilterChip } from '@/components/filter-chip';
import { CheckboxPopover } from '@/components/checkbox-popover';
import { CSContext } from '@/features/customer-success/context';
import { HSpace } from '@/components/spacing';
import { BigButton } from '@/components/big-button';
import { Spinner } from '@/Spinner';

export function GeneralFilters() {
  const ctx = useContext(CSContext);

  return (
    <Container>
      <Container style={{ padding: `4px 0 0 0` }}>
        <FilterChip
          onClick={() => ctx.toggleChipFilter(false)}
          vselected={!ctx.state.isTrialFilter}
        >
          All Clients{` `}
          {ctx.generalStats?.allCustomers
            ? `(${ctx.generalStats.allCustomers})`
            : ``}
        </FilterChip>
        <FilterChip
          onClick={() => ctx.toggleChipFilter(true)}
          vselected={ctx.state.isTrialFilter}
        >
          On Trial{` `}
          {ctx.generalStats?.customersOnTrial
            ? `(${ctx.generalStats.customersOnTrial})`
            : ``}
        </FilterChip>
        <SizeFilter />
        <ClientFilter />
      </Container>
      <HSpace />
      <HSpace />
      <BigButton onClick={() => ctx.selectPage(0)}>
        {!ctx.loading ? (
          `Submit`
        ) : (
          <Spinner size={20} color="pink" mt="0" relative />
        )}
      </BigButton>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
function ClientFilter() {
  const ctx = useContext(CSContext);
  return (
    <CheckboxPopover
      key={`clientFilter${Math.random()}`}
      options={ctx.state.aliasesFilter}
      emptyState=""
      onChange={ctx.setAliasFilter}
    >
      <FilterChip
        className={`label-select ${
          ctx.selectedAlias?.length > 0 ? `selected` : ``
        }`}
      >
        <span className="long-text">Clients</span>
        <VscFilter size={14} />
      </FilterChip>
    </CheckboxPopover>
  );
}

function SizeFilter() {
  const ctx = useContext(CSContext);
  return (
    <CheckboxPopover
      key={`sizeFilter${Math.random()}`}
      options={ctx.state.sizeFilter}
      emptyState=""
      onChange={ctx.setSizeFilter}
    >
      <FilterChip
        className={`label-select ${
          ctx.selectedSizeFilter?.length > 0 ? `selected` : ``
        }`}
      >
        <span className="long-text">Size</span>
        <VscFilter size={14} />
      </FilterChip>
    </CheckboxPopover>
  );
}
