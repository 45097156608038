import { CachePolicies, useFetch } from 'use-http';
import { useState } from 'react';
import useAsyncEffect from 'use-async-effect';
import { routes } from '@/webapi/routes';
import { WindowCache } from '@/utils/cache';
import { hashObject } from '@/components/hooks/use-cached-auto-save';
import { maybe } from '@/features/details/utils';

export interface MetricsData {
  addToCart: number;
  assistedRevenue: number;
  clicks: number;
  directRevenue: number;
  enteredCheckout: number;
  orderItemsCount: number;
  pageViews: number;
  productDetailViews: number;
  profit: number;
  returns: number;
  revenue: number;
  sessions: number;
  allocation: number;
  signups: number;
  chance: number;
  variant_mame: string;
  subscriptions: number;
  sumSessionsDuration: number;
  transactions: number;
  users: number;

  name: string;
  goal: string;
  alias: string;
  use_case: string;
  control: string;
  variant: string;
}

export interface DataResponse {
  data: {
    metrics: MetricsData[];
  };
  total: number;
  totalTrial: number;
}

interface CcSummaryOpts {
  stores: string[];
  sizes: string[];
  startAt?: string;
  endAt?: string;
  isTrial: boolean;
  searchToken: string;
  page: number;
  size: number;
}

export interface CsGeneralResp {
  running: number;
  abTests: number;
  movedTo100: number;
  paused: number;
  clientsWithoutTests: number;
  listOfClientsWithoutTests: string[];
  drafts: number;
  customersOnTrial: number;
  allCustomers: number;
}

const fiveMin = 1000 * 60 * 5;
const cache = maybe(() => new WindowCache<any>(`csteam`, fiveMin))!;
export function useApi() {
  const { get, post, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: true,
    },
  }));
  const [clients, setClients] = useState<Array<string>>([]);
  useAsyncEffect(async (isActive) => {
    const result = await get(routes.listAllAccounts);
    if (isActive() && result?.accounts?.length > 0) {
      setClients([`ALL`, ...result.accounts]);
    }
  }, []);

  const getMetrics = async (query: CcSummaryOpts): Promise<DataResponse> => {
    const key = hashObject(query);
    if (await cache.exists(key)) {
      const promise1 = await cache.get(key);
      if (typeof promise1 !== `undefined`) {
        return promise1;
      }
    }
    const promise = await post(routes.csSummary, query);
    if (promise?.data?.metrics?.length) {
      await cache.set(key, promise);
    }
    return promise;
  };

  const generalStats = async (query: CcSummaryOpts): Promise<CsGeneralResp> => {
    const key = `g${hashObject(query)}`;
    if (await cache.exists(key)) {
      const promise1 = await cache.get(key);
      if (typeof promise1 !== `undefined`) {
        return promise1;
      }
    }
    const promise = await post(routes.csGeneral, query);
    if (promise?.data?.metrics?.length) {
      await cache.set(key, promise);
    }
    return promise;
  };

  return { loading, clients, getMetrics, generalStats };
}
