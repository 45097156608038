/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { DebouncedState } from 'use-debounce/lib/useDebouncedCallback';
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params';
import { CheckboxOption } from '@/components/checkbox-popover';
import {
  CsGeneralResp,
  DataResponse,
  useApi,
} from '@/features/customer-success/api';
import { clone } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/shared';
import { getRange } from '@/features/dashboard/reports/api';
import { DateRange } from '@/features/dashboard/reports/models';

export const NumParamWithDefault = withDefault(NumberParam, 0);

interface State {
  aliasesFilter: Array<CheckboxOption>;
  sizeFilter: Array<CheckboxOption>;
  searchTerm: string;
  metrics: DataResponse;
  total: number;
  totalTrial: number;
  isTrialFilter: boolean;
}

interface CustomerSuccessContext {
  state: State;
  setSizeFilter: (sizeFilter: CheckboxOption[]) => void;
  selectedSizeFilter: CheckboxOption[];
  changeSearch: DebouncedState<(ev: any) => void>;

  setAliasFilter: (sizeFilter: CheckboxOption[]) => void;
  selectedAlias: CheckboxOption[];
  toggleChipFilter: (b: boolean) => void;
  loading: boolean;
  onSubmit: (stores: any, sizes: any, p: number) => Promise<void>;
  prevPage: () => Promise<void>;
  nextPage: () => Promise<void>;
  generalStats?: CsGeneralResp | undefined;
  selectPage: (p: number) => Promise<void>;
  page: number;
}

export const CSContext = React.createContext({} as CustomerSuccessContext);
const storeSizes = [
  { caption: `ENT`, id: `ENT`, selected: false },
  { caption: `MM`, id: `MM`, selected: false },
  { caption: `SMB+`, id: `SMB+`, selected: false },
  { caption: `SMB`, id: `SMB`, selected: false },
  { caption: `VSB`, id: `VSB`, selected: false },
];
const getQueryParamValue = (name: string) =>
  new URL(window.location.href).searchParams.get(name);

export function newCSContext() {
  const [searchTerm, setSearchTerm] = useQueryParam(`q`, StringParam);
  const [sizeQueryParam, _setSelectedSizeFiter] = useQueryParam(
    `size`,
    ArrayParam,
  );

  const [sizeFilter, setSizeFilterParamState] =
    useState<CheckboxOption[]>(storeSizes);
  const setSizeFilterParam = (opts: CheckboxOption[]) => {
    _setSelectedSizeFiter(opts.filter((o) => o.selected).map((o) => o.id));
    setSizeFilterParamState(opts);
  };

  const [storesQueryParam, setSelectedStores] = useQueryParam(
    `stores`,
    ArrayParam,
  );

  const [isTrial, setIsTrial] = useQueryParam(`trial`, BooleanParam);

  const [aliasesFilter, _setAliasFilterParam] = useState([]);
  const setAliasFilterParam = (opts: CheckboxOption[]) => {
    _setAliasFilterParam(
      clone(opts).sort((a) => (a.id === `ALL` ? -1 : a.selected ? -1 : 1)),
    );
    setSelectedStores(opts.filter((o) => o.selected).map((o) => o.id));
  };

  const [total, setTotal] = useQueryParam(`total`, NumParamWithDefault);
  const [totalTrial, setTotalTrial] = useQueryParam(
    `totalTrial`,
    NumParamWithDefault,
  );
  const [page, setPage] = useQueryParam(`page`, NumParamWithDefault);
  const [metrics, setMetrics] = useState<DataResponse>();

  const { getMetrics, loading, clients, generalStats } = useApi();

  useEffect(() => {
    if (clients?.length) {
      setAliasFilterParam(
        clients.map((alias) => ({
          caption: alias,
          selected: !!storesQueryParam?.find((x) => x === alias),
          id: alias,
        })),
      );
      setSizeFilterParam(
        storeSizes.map((s) => ({
          ...s,
          selected: !!sizeQueryParam?.find((x) => x === s.id),
        })),
      );
      if (storesQueryParam?.length > 0 || sizeQueryParam?.length > 0) {
        onSubmit(storesQueryParam, sizeQueryParam);
      }
      getGeneralMetrics(storesQueryParam, sizeQueryParam).then((r) =>
        setStats(clone(r)),
      );
    }
  }, [clients.length]);

  const setSizeFilter = (next: CheckboxOption[]) => {
    setSizeFilterParam(
      isAllSelected(next)
        ? clone(next).map((s) => {
            s.selected = s.id === `ALL`;
            return s;
          })
        : next,
    );
  };

  const getQuery = (_stores?: any, _sizes?: any, _page?: number) => {
    const stores =
      _stores || aliasesFilter.filter((l) => l.selected).map((l) => l.id);
    const sizes =
      _sizes || sizeFilter.filter((l) => l.selected).map((l) => l.id);
    const dates = getDateRangeForRequest();

    return {
      isTrial,
      size: 100,
      startAt: dates?.startAt,
      endAt: dates?.endAt,
      sizes,
      stores,
      page: typeof _page === `number` ? _page : page,
      searchToken: searchTerm,
    };
  };

  const getGeneralMetrics = async (_stores?: any, _sizes?: any) =>
    generalStats(getQuery(_stores, _sizes));
  const [stats, setStats] = useState<CsGeneralResp | undefined>();

  const onSubmit = async (_stores?: any, _sizes?: any, _page?: number) => {
    if (loading) {
      return;
    }
    const query = getQuery(_stores, _sizes, _page);
    const metrics = await getMetrics(query);
    setMetrics(metrics);
    setTotal(metrics.total);
    setTotalTrial(metrics.totalTrial);

    const csGeneralResp = await generalStats(query);
    setStats(clone(csGeneralResp));
  };
  const nextPage = async () => {
    if (loading) {
      return;
    }
    await onSubmit(undefined, undefined, page + 1);
    setPage(page + 1);
  };
  const prevPage = async () => {
    if (loading) {
      return;
    }
    if (page >= 1) {
      await onSubmit(undefined, undefined, page - 1);
      setPage(page - 1);
    }
  };
  const selectPage = async (p: number) => {
    if (loading) {
      return;
    }
    await onSubmit(undefined, undefined, p);
    setPage(p);
  };

  return {
    state: {
      total,
      totalTrial,
      metrics,
      searchTerm,
      isTrialFilter: isTrial,
      sizeFilter: sizeFilter as any,
      aliasesFilter,
    },
    page,
    loading,
    selectedAlias: aliasesFilter,
    prevPage,
    nextPage,
    selectPage,
    selectedSizeFilter: sizeFilter as any,
    toggleChipFilter: setIsTrial,
    onSubmit,
    setAliasFilter: setAliasFilterParam,
    changeSearch: setSearchTerm,
    setSizeFilter,
    generalStats: stats,
  } as CustomerSuccessContext;
}

const isAllSelected = (checkboxOptions: Array<CheckboxOption>) =>
  !!checkboxOptions.find((l) => l.selected && l.id === `ALL`);

const getDateRangeForRequest = () => {
  const dateRange = getQueryParamValue(`rn`);

  if (dateRange === DateRange.LATEST) {
    return undefined;
  }
  if (dateRange !== DateRange.CUSTOM) {
    return getRange(dateRange as DateRange);
  }
  if (dateRange === DateRange.CUSTOM) {
    const startAt = new Date(getQueryParamValue(`s`)) as any;
    const endAt = new Date(getQueryParamValue(`e`)) as any;
    return { startAt, endAt };
  }

  return undefined;
};
