/* eslint-disable no-bitwise */
export const colors = [`#f2f2f2`, `#e0e0e0`];
let prevColor = ``;
let prevStr = ``;
export const simpleHash = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

export function stringToColor(str: string) {
  let colorIndex = Math.abs(simpleHash(str)) % colors.length;
  colorIndex = (colorIndex + 1) % colors.length;
  let color = colors[colorIndex];
  if (prevColor + prevStr === ``) {
    prevColor = color;
    prevStr = str;
  }
  if (
    (prevColor === color && prevStr !== str) ||
    (prevColor !== color && prevStr === str)
  ) {
    colorIndex = (colorIndex + 1) % colors.length;
    color = colors[colorIndex];
  }
  prevColor = color;
  prevStr = str;
  return color;
}
