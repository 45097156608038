/* eslint-disable react/destructuring-assignment,no-nested-ternary */
import React from 'react';
import styled from 'styled-components';
import { CellContext, ColumnDef } from '@tanstack/react-table';
import { MetricsData } from '@/features/customer-success/api';
import { maybe } from '@/features/details/utils';
import { MetricKind } from '@/webapi/models';

const rateCell =
  (denominator: string, nominator: string, isDollar?: boolean, digits = 2) =>
  (goal?: string) =>
  (p: CellContext<MetricsData, any>) => {
    const isGoal = goal === p.row.original.goal;
    if (p.row.original[denominator] === 0) {
      return <TCel isGoal={isGoal}>0</TCel>;
    }
    const rate =
      (p.row.original[nominator] / p.row.original[denominator]) *
      (isDollar ? 1 : 100);
    if (isControl(p)) {
      return <TCel isGoal={isGoal}>{formatRate(rate, isDollar, digits)}</TCel>;
    }

    const control = getControlRow(p);
    if (control[denominator] === 0) {
      return <TCel isGoal={isGoal}>{formatRate(rate, isDollar, digits)}</TCel>;
    }
    const controlRate =
      (control[nominator] / control[denominator]) * (isDollar ? 1 : 100);
    const rateUplift = (rate / controlRate - 1) * 100;

    return (
      <TCel isGoal={isGoal}>
        {formatRate(rate, isDollar, digits)} {getCellUplift(rateUplift)}
      </TCel>
    );
  };
export const getColumns = () =>
  [
    {
      header: `Alias`,
      accessorKey: `alias`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue()}</TCel>,
    },
    {
      header: `Name`,
      accessorKey: `name`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue()}</TCel>,
    },
    {
      header: `Variant`,
      accessorKey: `variant`,
      sortingFn: `alphanumeric`,
      size: 90,
      cell: (p) => <TCel>{p.row.original.variant_mame}</TCel>,
    },
    {
      header: `Chance`,
      accessorKey: `chance`,
      size: 50,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue()}%</TCel>,
    },
    {
      header: `Trial`,
      accessorKey: `in_trial`,
      size: 40,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{`${p.getValue() === `true` ? `Yes` : `No`}`}</TCel>,
    },
    {
      header: `Size`,
      accessorKey: `size`,
      size: 40,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue()}</TCel>,
    },
    {
      header: `Sessions`,
      accessorKey: `sessions`,
      sortingFn: `alphanumeric`,
      cell: makeRatioCell(`sessions`),
    },
    {
      header: `Users`,
      accessorKey: `users`,
      sortingFn: `alphanumeric`,
      cell: makeRatioCell(`users`),
    },
    {
      header: `Transactions`,
      accessorKey: `transactions`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue().toLocaleString()}</TCel>,
    },
    {
      header: `CR`,
      accessorKey: `cr`,
      sortingFn: `alphanumeric`,
      cell: rateCell(`sessions`, `transactions`)(MetricKind.CONVERSION_RATE),
    },
    {
      header: `PSV`,
      accessorKey: `psv`,
      sortingFn: `alphanumeric`,
      cell: rateCell(`sessions`, `revenue`, true)(MetricKind.PER_SESSION_VALUE),
    },
    {
      header: `Revenue`,
      accessorKey: `revenue`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>${Math.round(p.getValue()).toLocaleString()}</TCel>,
    },
    {
      header: `AOV`,
      accessorKey: `aov`,
      sortingFn: `alphanumeric`,
      cell: rateCell(
        `transactions`,
        `revenue`,
        true,
        0,
      )(MetricKind.AVG_ORDER_VALUE),
    },
    {
      header: `Assisted Revenue`,
      accessorKey: `assistedRevenue`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>${p.getValue().toLocaleString()}</TCel>,
    },
    {
      header: `Direct Revenue`,
      accessorKey: `directRevenue`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>${p.getValue().toLocaleString()}</TCel>,
    },
    {
      header: `Clicks`,
      accessorKey: `clicks`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue().toLocaleString()}</TCel>,
    },
    {
      header: `Checkout Rate`,
      accessorKey: `enteredCheckoutRate`,
      sortingFn: `alphanumeric`,
      cell: rateCell(`sessions`, `enteredCheckout`)(MetricKind.CHECKOUT_RATE),
    },
    {
      header: `IPP`,
      accessorKey: `ipp`,
      sortingFn: `alphanumeric`,
      cell: (p) => (
        <TCel>
          {(
            p.row.original.orderItemsCount / p.row.original.transactions
          ).toLocaleString()}
        </TCel>
      ),
    },
    {
      header: `Page Views`,
      accessorKey: `pageViews`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue().toLocaleString()}</TCel>,
    },
    {
      header: `Profit`,
      accessorKey: `profit`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>${Math.round(p.getValue()).toLocaleString()}</TCel>,
    },
    {
      header: `Profit Per Session`,
      accessorKey: `pps`,
      sortingFn: `alphanumeric`,
      cell: rateCell(`sessions`, `profit`, true)(MetricKind.PROFIT_PER_SESSION),
    },
    {
      header: `Returns`,
      accessorKey: `returns`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue().toLocaleString()}</TCel>,
    },
    {
      header: `Signups`,
      accessorKey: `signups`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue().toLocaleString()}</TCel>,
    },
    {
      header: `Signups rate`,
      accessorKey: `signupsRate`,
      sortingFn: `alphanumeric`,
      cell: rateCell(`sessions`, `signups`)(MetricKind.SIGNUPS_RATE),
    },
    {
      header: `Subscriptions`,
      accessorKey: `subscriptions`,
      sortingFn: `alphanumeric`,
      cell: (p) => <TCel>{p.getValue().toLocaleString()}</TCel>,
    },
    {
      header: `Subscriptions Rate`,
      accessorKey: `subscriptionsRate`,
      sortingFn: `alphanumeric`,
      cell: rateCell(`sessions`, `subscriptions`)(MetricKind.SUBSCRIPTION_RATE),
    },
    {
      header: `ASD`,
      accessorKey: `sumSessionsDuration`,
      sortingFn: `alphanumeric`,
      cell: (p) => (
        <TCel>
          {Math.round(
            p.row.original.sumSessionsDuration / p.row.original.sessions,
          ).toLocaleString()}
          s
        </TCel>
      ),
    },
    {
      header: `Add To Cart Rate`,
      accessorKey: `addToCartRate`,
      sortingFn: `alphanumeric`,
      cell: rateCell(`sessions`, `addToCart`)(MetricKind.ADD_TO_CART_RATE),
    },
    {
      header: `Days`,
      accessorKey: `startDate`,
      sortingFn: `alphanumeric`,
      size: 50,
      cell: (p) => (
        <TCel>
          {maybe(() =>
            Math.round(
              (Date.now() - new Date(p.getValue()).getTime()) /
                (1000 * 60 * 60 * 24),
            ),
          ).toLocaleString()}
        </TCel>
      ),
    },

    {
      header: `OTW`,
      accessorKey: `odds_to_win`,
      sortingFn: `alphanumeric`,
      size: 80,
      cell: (p) => <TCel>{p.getValue() as string}</TCel>,
    },
    {
      header: `Link`,
      accessorKey: `link`,
      sortingFn: `alphanumeric`,
      size: 50,
      cell: (p) => (
        <TA
          target="blank"
          href={`https://${window.location.host}/details?al=${p.row.original.alias}&expId=${p.row.original.use_case}`}
        >
          link
        </TA>
      ),
    },
    {
      header: `use_case`,
      accessorKey: `use_case`,
      sortingFn: `alphanumeric`,
      hidden: true,
      cell: (p) => <TCel>{p.getValue()}</TCel>,
    },
  ] as ColumnDef<MetricsData, any>[];

const TCel = styled.p`
  word-break: break-word;
  font-size: 1.4rem;
  font-family: 'JetBrains Mono', serif;
  ${(p) =>
    p.isGoal &&
    `
    font-weight: 600;
    background-color: rgba(0, 123, 255, 0.1);
    border-radius: 4px;
    padding: 2px 6px;
    border-left: 3px solid #007bff;
  `}
`;
const TA = styled.a`
  font-size: 1.4rem;
  font-family: 'JetBrains Mono', serif;
`;

const getControlRow = (p: CellContext<MetricsData, any>) =>
  p?.table
    ?.getCoreRowModel()
    ?.rows?.find((r) => r.original.variant === p.row.original.control)
    ?.original;
const calcDenumUplift = (
  p: CellContext<MetricsData, any>,
  sessions: string,
  controlRow: MetricsData,
) =>
  (maybe(
    () =>
      (p.row.original[sessions] * controlRow.chance) /
      (controlRow[sessions] * p.row.original.chance),
    0,
  ) -
    1) *
  100;
const isControl = (p: CellContext<MetricsData, any>) =>
  p.row.original.control === p.row.original.variant;

const getCellUplift = (uplift: number) => {
  const isPositive = uplift >= 3;
  const isNeutral = uplift < 3 && uplift >= -3;

  return uplift === 0 || !Number.isFinite(uplift) ? (
    ``
  ) : (
    <span
      style={{
        color: isPositive ? `#0a874d` : isNeutral ? `#946800` : `#c93d00`,
      }}
    >
      ({uplift > 0 ? `+` : ``}
      {uplift.toFixed(1)}%)
    </span>
  );
};

const makeRatioCell = (key: string) => (p: CellContext<MetricsData, any>) => {
  const value = p.getValue();
  if (isControl(p) || value === 0) {
    return <TCel>{value.toLocaleString()}</TCel>;
  }
  const uplift = calcDenumUplift(p, key, getControlRow(p));
  return (
    <TCel>
      {value.toLocaleString()} {getCellUplift(uplift)}
    </TCel>
  );
};

const formatRate = (rate: number, isDollar: boolean, digits: number) => {
  // @ts-ignore
  const number = rate.toFixed(digits) * 1;
  if (isDollar) {
    return `$${number.toLocaleString()}`;
  }
  return `${number.toLocaleString()}%`;
};
